import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ResponsiveAppBar from './components/appbar/Appbar';
import LandingPage from './Pages/LandingPage/LandingPage';
import LoginPage from './Pages/LoginPage/LoginPage';
import HomePage from './Pages/HomePage/HomePage';
import SignUp from './Pages/SignupPage/SignupPage';
import ShopPage from './Pages/ShopPage/ShopPage';
import MapPage from './Pages/Map/MapPage';
import SettingsPage from './Pages/SettingsPage/SettingsPage';
import ShopOwnerPage from './Pages/ShopOwnerPage/ShopOwnerPage';
import AdminPage from './Pages/AdminPage/AdminPage';
import AccountPage from './Pages/AccountPage/AccountPage';
import ShopHoursPage from './Pages/ShopOwnerPage/ShopHoursPage/ShopHoursPage';
import ShopServicesPage from './Pages/ShopOwnerPage/ShopServicesPage/ShopServicesPage';
import ShopSettingsPage from './Pages/ShopOwnerPage/ShopSettingsPage/ShopSettingsPage';
import AccountDetailsPage from './Pages/AccountPage/AccountDetailsPage/AccountDetailsPage';
import './App.css';



const App: React.FC = () => {
  
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);


  useEffect(() => {
    const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
    if (!existingScript) {
      const apiKey = process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setIsGoogleMapsLoaded(true);
      };
      document.head.appendChild(script);
    } else {
      // If script is already loaded, just set state to true
      setIsGoogleMapsLoaded(true);
    }
  }, []);

  if (!isGoogleMapsLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <div className="App">
    <Router>
      <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/shop/:shop_id" element={<ShopPage />} />
          <Route path="/shopowner" element={<ShopOwnerPage />} />
          <Route path="/shopowner/settings" element={<ShopSettingsPage />} />
          <Route path="/shopowner/hours" element={<ShopHoursPage />} />
          <Route path="/shopowner/services" element={<ShopServicesPage />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/account/details" element={<AccountDetailsPage />} />
        </Routes>
    </Router>
    </div>
  );
};

export default App;
