import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import AccountCalendar from '../../components/accountCalendar/AccountCalendar';


const AccountPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="column"
        flexWrap="wrap"
        sx={{ gap: 2, padding: 2 }}
        >
        <Button onClick={() => {navigate("/account/details")}}
          variant="contained"
          sx={{
          flexGrow: 6,
          minWidth: 100,
          textAlign: 'center',
          background: 'linear-gradient(90deg, #b62a73 1%, #ffcdb3 100%)',
          }}
          >
          אזור אישי
        </Button>
      </Box>
      <AccountCalendar />
    </div>
  );
}

export default AccountPage;