import React, { useEffect, useRef } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { ShopInfo } from '../../resources/shopsDB/ShopsDB';
import { fetchBucketImage } from '../../components/auth/Bucketimages';
import Box from '@mui/material/Box';

interface ShopCardProps {
    shopInfo: ShopInfo;
    mode: "navigate" | "upload"; // New prop to determine behavior
    onNavigate?: () => void;
    onUpload?: () => void;
}

const ShopCard: React.FC<ShopCardProps> = ({ shopInfo, mode, onNavigate, onUpload }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [imageUrl, setImageUrl] = React.useState('');

    useEffect(() => {
        fetchBucketImage(setImageUrl, shopInfo.header_image);
    }, [shopInfo]);

    useEffect(() => {
        if (imageUrl) {
            setIsLoading(false);
        }
    }, [imageUrl]);

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent bubbling
        if (mode === "navigate" && onNavigate) {
            onNavigate();
        } else if (mode === "upload") {
            onUpload();
        }
    };

    if (isLoading) {
        return <div></div>;
    } else {
        return (
            <Card sx={{ position: 'relative' }} onClick={handleClick}>
                <CardMedia
                    component="img"
                    sx={{
                        position: 'relative',
                        height: '200px',
                        width: '200px',
                        cursor: 'pointer',
                    }}
                    image={imageUrl}
                    alt={shopInfo.name}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0, 0, 0, 0.5)',
                        padding: 1,
                        color: 'white',
                    }}
                >
                    <CardContent sx={{ padding: '10px', position: 'relative', height: '100%' }}>
                        <Typography
                            sx={{
                                fontSize: 'clamp(12px, 5vw, 16px)',
                                fontFamily: "'Roboto Slab', serif",
                                position: 'absolute',
                                bottom: '50%',
                                left: '50%',
                                transform: 'translate(-50%, 50%)',
                                padding: '8px 16px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                textAlign: 'center',
                            }}
                        >
                            {shopInfo.name}
                        </Typography>
                    </CardContent>
                </Box>
            </Card>
        );
    }
};

export default ShopCard;
