import React, { useEffect, useState, useRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import { auth } from '../auth/firebase';
import momentPlugin from '@fullcalendar/moment';
import { Appointment, createOwnerBlockHours, getAppointmentInfo, deleteAppointment } from '../../resources/appointmentsDB/AppointmentsDB';
// import './ShopOwnerCalendar.css'
import { getPublicShopInfo, getShopWeeklySchedule } from '../../resources/shopsDB/ShopsDB';
import rrulePlugin from '@fullcalendar/rrule';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import {Select, MenuItem} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { bookAppointment } from '../../resources/appointmentsDB/AppointmentsDB';
import { Grid } from '@mui/material';

import deleteIcon from '../iconrow/icons8-x-48.png';

import ImageMenuButton from '../iconrow/imagemenubutton/ImageMenuButton';
import { Avatar } from '@mui/material';
import ClientCard from './ClientCard';

interface ShopOwnerCalendarProps {
  shopId: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});




const ShopOwnerCalendar: React.FC<ShopOwnerCalendarProps> = ({shopId}) => {
  const slotGranularity = 10
  const TEXT_MAX_LENGTH = 100; // Define the maximum length for the string
  const calendarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [focusDate, setFocusDate] = useState(null);
  const [currentEvents, setCurrentEvents] = useState<Appointment[]>([]);

  const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Add Appointment'); // Default to 'Add Appointment'
  const [eventSelectWindow, setEventSelectWindow] = useState(false);

  var refClickInfo = useRef(null);
  var client_id = useRef(null);
  var client_name = useRef(null);
  var client_phone = useRef(null);
  var client_requests = useRef(null);
  var service_name = useRef(null);
  var client_photo = useRef(null);
  // const [eventSelected, setEventSelected] = useState(null);


  const [nameFieldValue, setnameFieldValue] = useState('');
  const [telephoneFieldValue, settelephoneFieldValue] = useState('');
  const [serviceFieldValue, setserviceFieldValue] = useState('');
  const [requestFieldValue, setrequestFieldValue] = useState('');

  

  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  
  



  useEffect(() => {
    fetchEvents();
  }, []);

  

  const handleNameFieldChange = (event) => {
    setnameFieldValue(event.target.value);
  };

  const handleTelephoneFieldChange = (event) => {
    settelephoneFieldValue(event.target.value);
  };

  const handleServiceFieldChange = (event) => {
    setserviceFieldValue(event.target.value);
  };

  const handleRequestFieldChange = (event) => {
    setrequestFieldValue(event.target.value);
  };


  useEffect(() => {
    if (focusDate && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(focusDate);
      setFocusDate(null); // Reset focus date after navigating
    }
  }, [focusDate]);

  const fetchEvents = async () => {
    await setIsLoading(true);
    // const shopHours = await getShopAvailableHours(shopId)
    const privateAppointments = await getAppointmentInfo(shopId, true)
    var events_list: Appointment[] = await getShopWeeklySchedule(shopId)
    events_list.forEach((event) => {
      event['display'] = 'background'   
    })
    // events_list.push(my_event)
    events_list.push(...privateAppointments)
    await setCurrentEvents(events_list)
    await setIsLoading(false);
  }

  const confirmAppointment = async () => {
    setConfirmWindowOpen(false)
    // const endTimeDate = GetEndAppointmentDate(selectedEvent.date, selectedService.time_length)
    const shopInfo = await getPublicShopInfo(null)

    const client_id = auth.currentUser.uid

    if (selectedOption === 'Add Appointment'){
      var phone_number = telephoneFieldValue
      if (phone_number.length > 9) {
        phone_number = (phone_number.slice(1, 10));
      }
      await bookAppointment({
          title: shopInfo.name,
          start: selectedEvent.start,
          end: selectedEvent.end,
          shop_id: shopId,
          client_id: client_id,
        },
        {
          service_name: serviceFieldValue,
          client_name: nameFieldValue,
          client_phone: `+972${phone_number}`,
          client_requests: requestFieldValue,
          client_photo: '',
          shop_address: shopInfo.address,
          shop_phone: shopInfo.phone,
          shop_image: shopInfo.header_image,
        }
      )
    }
    else {
        await createOwnerBlockHours({
        title: shopInfo.name,
        start: selectedEvent.start,
          end: selectedEvent.end,
        shop_id: shopId,
        client_id: client_id,
      },
      {
        service_name: '',
        client_name: 'Blocked Hours',
        client_phone: '',
        client_requests: '',
        client_photo: '',
        shop_address: shopInfo.address,
        shop_phone: shopInfo.phone,
        shop_image: shopInfo.header_image,
      })
    }
    await fetchEvents()
    setFocusDate(selectedEvent.start);
  }

  const handleEventDidMount = (info) => {
    if (info.view.type === 'dayGridMonth') {
      info.el.style.display = 'none';
    }
  };
  

  // Adds color coding to the day cells based on the availability of events
  const dayCellDidMount = (arg) => {
    const dateNumber = arg.el.querySelector('.fc-daygrid-day-number');
    if (dateNumber) {
      dateNumber.style.pointerEvents = 'none';
      dateNumber.style.cursor = 'default';
    }
    else {
      return
    }
    const { date, el } = arg;

    if (currentEvents.length > 0) {
      const allAvailable = currentEvents.every(event => (event as any).title! == 'available');
      const someAvailable = currentEvents.some(event => (event as any).title! == 'available');

      if (allAvailable) {
        el.classList.add('fc-day-all-available');
      } else if (someAvailable) {
        el.classList.add('fc-day-some-available');
      } else {
         el.classList.add('fc-day-none-available');
      }
    }
  };


  /* handles how the calendar should behave when a date is selected
  if the view is month, change to day view
  if the hours are selected create an event and add it to the database
  */
  const handleDateSelect = async (selectInfo: any) => {
    if (selectInfo.view.type === 'dayGridMonth') {
      let calendarApi = selectInfo.view.calendar
      calendarApi.changeView('timeGridDay', selectInfo.dateStr);
      return
    }
    if (!selectInfo.start ||!selectInfo.end){
      return
    }
    // if (selectInfo.dateStr) {
    //   return
    // }

    const calendarEvents =  selectInfo.view.calendar.getEvents()

    
    // let confirmSlot = window.confirm('Would you like to block hours between ' + selectInfo.startStr.split('T')[1].split('+')[0].slice(0,-3) + ' to ' + selectInfo.endStr.split('T')[1].split('+')[0].slice(0,-3))
    let calendarApi = selectInfo.view.calendar
    

    calendarApi.unselect() // clear date selection

    setSelectedEvent(selectInfo)
    setConfirmWindowOpen(true)

  }

  const handleEventRemove = async () => {
    console.log(refClickInfo.current)
    const removeEvent = window.confirm(`Are you sure you want to delete the event ${refClickInfo.current.event.startStr.split('T')[1].split('+')[0].substr(0, 5)}-${refClickInfo.current.event.endStr.split('T')[1].split('+')[0].substr(0, 5)}?`)
    if (removeEvent) {
      await deleteAppointment(refClickInfo.current.event.id)
      await fetchEvents()
      setFocusDate(refClickInfo.current.event.start);
      setEventSelectWindow(false)
    }
  }


  const handleEventClick = async (clickInfo: any) => {
    if (!clickInfo.event.backgroundColor) {
      return
    }
    refClickInfo.current = clickInfo
    client_name.current = clickInfo.event.extendedProps.client_name
    client_phone.current = clickInfo.event.extendedProps.client_phone
    client_requests.current = clickInfo.event.extendedProps.client_requests
    client_photo.current = clickInfo.event.extendedProps.client_photo
    let calendarApi = clickInfo.view.calendar
    if (calendarApi.view.type === 'dayGridMonth') {
      return
    }
    setEventSelectWindow(true)
    // if (DeleteAppointments === false) {
    //   return
    // }
    
    
  }





  const renderEventContent = (eventInfo: any) => {
    let calendarApi = eventInfo.view.calendar;
    if (calendarApi.view.type === 'dayGridMonth') {
      return null;
    } else {
      return (
        <div style={{
          display: 'flex', // Use flexbox for layout
          alignItems: 'flex-start', // Align items to the top
          fontFamily: "'Roboto Slab', serif",
          width: '100%'
        }}>
          {Object.keys(eventInfo.event.extendedProps).length ?
          <Avatar alt="" src={eventInfo.event.extendedProps.client_photo ? eventInfo.event.extendedProps.client_photo : "/static/images/avatar/2.jpg"}
          style={{
            marginTop: '10px', // Lower the avatar from the top
            marginLeft: '4px',
            marginRight: '10px' // Increase the padding between avatar and text
          }}  
          /> : 
          null}
          
          {/* Textual content in a column */}
          <div style={{ 
            flex: 1, // Allow this div to grow and take available space
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap'
          }}>
            {eventInfo.event.extendedProps.client_name}
            {eventInfo.event.extendedProps.client_phone && (
              <>
                <br />
                {eventInfo.event.extendedProps.client_phone}
              </>
            )}
            {eventInfo.event.extendedProps.service_name && (
              <>
                <br />
                <i>{eventInfo.event.extendedProps.service_name}</i>
              </>
            )}
            {eventInfo.event.extendedProps.client_requests && (
              <>
                <br />
                <div style={{ 
                  wordWrap: 'break-word', 
                  whiteSpace: 'pre-wrap',
                  direction: 'rtl',
                }}>
                  {eventInfo.event.extendedProps.client_requests.length < TEXT_MAX_LENGTH 
                    ? eventInfo.event.extendedProps.client_requests 
                    : eventInfo.event.extendedProps.client_requests.slice(0, TEXT_MAX_LENGTH) + '...'}
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
  }


  if (isLoading) {
    return <div>Loading events...</div>;
  }
    return (
      <div className='lilac-calendar'>
        {/* Add event dialog */}
        <React.Fragment>
          <Dialog
            open={confirmWindowOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { setConfirmWindowOpen(false) }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Book an Appointment"}</DialogTitle>
            <DialogContent>
              <Select
                value={selectedOption}
                onChange={(event) => setSelectedOption(event.target.value)}
                fullWidth
                variant="outlined"
                margin="dense"
              >
                <MenuItem value="Block Hours">Block Hours</MenuItem>
                <MenuItem value="Add Appointment">Add Appointment</MenuItem>
              </Select>
              {selectedOption === 'Add Appointment' && (
              <React.Fragment>
                <TextField
                  fullWidth 
                  margin="dense"
                  id="name"
                  name="name"
                  value={nameFieldValue}
                  onChange={handleNameFieldChange}
                  label="שם לקוח"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  margin="dense"
                  label="טלפון"
                  variant="outlined"
                  value={telephoneFieldValue}
                  onChange={handleTelephoneFieldChange}
                  placeholder="Phone Number"
                  InputProps={{
                      startAdornment: <InputAdornment position="start">+972</InputAdornment>,
                  }}
                  inputProps={{
                  maxLength: 10,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  }}
                  required
                />
                <TextField
                  fullWidth 
                  margin="dense"
                  id="service"
                  name="service"
                  value={serviceFieldValue}
                  onChange={handleServiceFieldChange}
                  label="טיפול"
                  variant="outlined"
                />
                <TextField
                  fullWidth 
                  margin="dense"
                  id="requests"
                  name="requests"
                  value={requestFieldValue}
                  onChange={handleRequestFieldChange}
                  label="הערות"
                  variant="outlined"
                  multiline
                  minRows={2}
                />
              </React.Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { setConfirmWindowOpen(false) }}>Cancel</Button>
              <Button onClick={() => { confirmAppointment() }}>Agree</Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>


        {/* Event select dialog */}
        <React.Fragment>
        <Dialog
            open={eventSelectWindow}
            TransitionComponent={Transition}
            // keepMounted
            onClose={() => { setEventSelectWindow(false) }}
            aria-describedby="alert-dialog-slide-description"
            sx={{ 
              '& .MuiDialog-paper': {
                backgroundColor: "#fff8f5", // Change this to your desired background color
              }
            }}
          >
            <DialogContent>
              <ClientCard imageUrl={client_photo.current} />
              <DialogContentText id="alert-dialog-slide-description" style={{ 
                  width: '100%', // Or any width you want the text to wrap within
                  wordWrap: 'break-word', // Ensure long words wrap to the next line
                  whiteSpace: 'pre-wrap', // Preserve spaces and wrap text
                  direction: 'rtl', // Align text from right to left
                  fontFamily: "'Roboto Slab', serif",
                }}>
                  {client_requests.current ? 
                  <>
                  <b>
                  בקשות הלקוח:
                  </b>
                  <br />
                  {client_requests.current}
                  </> : null}
              </DialogContentText>
                <Grid container flexDirection='row' justifyContent="center" sx={{background: "#fff8f5", marginTop: 2}}>
                    <Grid item sx={{ marginRight: 2 }}>
                        <ImageMenuButton  phoneNumber={client_phone.current}/>
                    </Grid>
                    <Grid item sx={{ marginLeft: 2 }}>
                      <Grid 
                      container 
                      flexDirection='column' 
                      alignItems="center" // Center items horizontally
                      justifyContent="center" // Center items vertically within the grid
                      sx={{
                        background: "#fff8f5",
                        // minHeight: '100vh', // Assuming you want it to take up the full viewport height
                        // or set a specific height if needed, e.g., height: '200px'
                      }}
                    >
                      <img src={deleteIcon} alt="Address logo" onClick={handleEventRemove} />
                      <>מחק</>
                    </Grid>
                  </Grid>
              </Grid>
              
            </DialogContent>
          </Dialog>
        </React.Fragment>
        <div className='demo-app-main'>
          <FullCalendar
          ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin, momentPlugin]}
            locale={'en-gb'}
            titleFormat={'D.M'}
            headerToolbar={{
              left: 'prev,next,today',
              center: 'title',
              right: 'listWeek,dayGridMonth,timeGridDay',
            }}
            initialView='listWeek'
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            views={{
              dayGridMonth: {
                selectable: false, // Disable multi-select for month view
              }
            }}
            editable={true}
            selectable={true}
            // selectAllow={handleSelectAllow} // Allow selection based on custom logic
            selectMirror={true}
            dayMaxEvents={true}
            events={currentEvents}
            slotDuration={{ minutes: slotGranularity }}
            // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
            dateClick={handleDateSelect}
            select={handleDateSelect}

            eventContent={renderEventContent} // custom render function
            dayMaxEventRows={0} // This prevents the "+X more" link from appearing
            eventClick={handleEventClick}
            // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            slotMinTime="08:00:00" // Show only from 8:00 AM
            slotMaxTime="22:00:00" // Show only until 10:00 PM
            slotLabelInterval={{ minutes: slotGranularity }} // Show labels for every hour
            allDaySlot={false}
            navLinks={true}
            selectLongPressDelay={0}
            height="auto"
            aspectRatio={1.35}
            dayCellDidMount={dayCellDidMount}
            eventDidMount={handleEventDidMount}
            eventStartEditable={false}
            eventResizableFromStart={false}
            eventDurationEditable={false}
            // you can update a remote database when these fire:
            // eventAdd={function(){}}
            //eventChange={function(){}}
            // eventRemove={function(){}}
            
          />
        </div>
      </div>
    )

}


  


  

export default ShopOwnerCalendar;