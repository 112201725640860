import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase"; // Adjust the path to your Firebase configuration
import { TextField, Button, Container, Typography, Box, Stepper, Step, StepLabel } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert'; // Import Alert component
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';


// Extend Window interface to include recaptchaVerifier
declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier | undefined;
  }
}

const PhoneAuth: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [recaptchaInitialized, setRecaptchaInitialized] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0); // Track the step (0 for phone number, 1 for OTP)
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const alertMessage = useRef("");
  const navigate = useNavigate();

  // Function to initialize reCAPTCHA
  const setupRecaptcha = () => {
    const recaptchaElement = document.getElementById("recaptcha-container");
    if (!recaptchaElement) {
      console.error("reCAPTCHA container element not found.");
      return;
    }

    if (!recaptchaInitialized) {
      const recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible", // Can be 'normal', 'compact', or 'invisible'
          callback: (response: string) => {
            console.log("reCAPTCHA solved:", response);
          },
          "expired-callback": () => {
            console.warn("reCAPTCHA expired. Please retry.");
          },
        },
      );

      window.recaptchaVerifier = recaptchaVerifier; // Assign to window
      recaptchaVerifier.render().then(() => {
        setRecaptchaInitialized(true);
      });
    }
  };

  // Initialize reCAPTCHA only after component mounts
  useEffect(() => {
    setupRecaptcha(); // Ensure reCAPTCHA is set up when component is ready
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  const sendOTP = async () => {
    try {
      const recaptchaVerifier = window.recaptchaVerifier;
      if (!recaptchaVerifier) {
        console.error("reCAPTCHA is not initialized.");
        return;
      }

      var phone_number = phoneNumber
      if (phone_number.length < 9) {
        alertMessage.current = "Please enter a valid phone number.";
        setSnackbarOpen(true);
        return
      }
      
      if (phone_number.length == 10) {
        phone_number = phoneNumber.substr(1,);
      }
      phone_number = `+972${phone_number}`;


      const confirmation = await signInWithPhoneNumber(auth, phone_number, recaptchaVerifier);
      setConfirmationResult(confirmation);
      console.log("OTP sent successfully!");
      setStep(1); // Move to the next step (OTP input)
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const verifyCode = async () => {
    try {
      if (confirmationResult) {
        const result = await confirmationResult.confirm(verificationCode);
        console.log("User signed in successfully:", result.user);

        // Navigate to /map after successful login
        navigate("/map");
      }
    } catch (error) {
      if (error.code === "auth/invalid-verification-code") {
        alertMessage.current = "קוד אימות לא נכון, אנא נסה שוב.";
        setSnackbarOpen(true);
      }
      console.error("Error verifying code:", error);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        {/* <Typography variant="h4" gutterBottom>
          Phone Authentication
        </Typography> */}

        {/* Stepper */}
        <Stepper activeStep={step} alternativeLabel >
          <Step>
            <StepLabel sx={{ direction: 'rtl' }}>שליחת SMS</StepLabel>
          </Step>
          <Step>
            <StepLabel sx={{ direction: 'rtl' }}>אישור OTP</StepLabel>
          </Step>
        </Stepper>

        {/* Step 1: Enter Phone Number */}
        {step === 0 && (
          <>
            <TextField
              label="טלפון"
              variant="outlined"
              fullWidth
              autoFocus
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{ marginBottom: 2,  marginTop: 2}}
              InputProps={{
                startAdornment: <InputAdornment position="start">+972</InputAdornment>,
              }}
              inputProps={{
              maxLength: 10,
              inputMode: 'numeric',
              pattern: '[0-9]*',
              }}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={sendOTP}
              sx={{ marginBottom: 2, direction: 'rtl',}}
            >
              שלח SMS
            </Button>
          </>
        )}

        {/* Step 2: Enter Verification Code */}
        {step === 1 && (
          <>
            <TextField
              label="Verification Code"
              variant="outlined"
              fullWidth
              autoFocus
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              sx={{ marginBottom: 2, marginTop: 2 }}
              inputProps={{
                maxLength: 10,
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={verifyCode}
              sx={{ marginBottom: 2, direction: 'rtl' }}
            >
              אימות מספר
            </Button>
          </>
        )}

        {/* reCAPTCHA container */}
        <div id="recaptcha-container"></div>

      <Snackbar
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      open={snackbarOpen}
                      autoHideDuration={1500}
                      onClose={() => { setSnackbarOpen(false) }}
                      // message={snackbarMessage}
                      // key={vertical + horizontal}
                    >
              <Alert  severity="error" onClose={() => setSnackbarOpen(false)}>
                  {alertMessage.current}
              </Alert>
      </Snackbar>
      </Box>
    </Container>
  );
};

export default PhoneAuth;
