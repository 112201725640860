import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ResponsiveAppBar from '../appbar/Appbar';
import { useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from '@fullcalendar/interaction'
import { getAppointmentInfo, deleteAppointment, getUserAppointments } from '../../resources/appointmentsDB/AppointmentsDB';
import { getPublicShopInfo, getShopWeeklySchedule, ShopInfo } from '../../resources/shopsDB/ShopsDB';
import momentPlugin from '@fullcalendar/moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {NailPolishSvg} from '../../Pages/Map/marker_clustering/nail-polish-bottle';
import AppointmentIconRow from '../../Pages/AccountPage/AppointmentIconRow'
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import ClientCard from '../shopOwnerCalendar/ClientCard';
import { Grid } from '@mui/material';
import ImageMenuButton from '../iconrow/imagemenubutton/ImageMenuButton';
import deleteIcon from '../../components/iconrow/icons8-x-48.png';
import { addDays, subDays } from 'date-fns';
import ShopCard from '../../Pages/HomePage/ShopCard';



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const AccountCalendar: React.FC = () => {
    const slotGranularity = 10
    const today = new Date();
    const thirtyDaysAgo = subDays(today, 30);

    const firstLoaded = useRef(true);
    const calendarRef = useRef(null);
    const refClickInfo = useRef(null);
    const focusDate = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    // const [focusDate, setFocusDate] = useState(null);
    // const [shopId, setShopId] = useState(shopId);
    const [currentEvents, setCurrentEvents] = useState([]);
    // const [userEvents, setUserEvents] = useState<any>([]);
    // const [selectedEvents, setSelectedEvents] = useState<any>([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
    const [confirmWindowMessage, setConfirmWindowMessage] = useState('');
    const [eventSelectWindow, setEventSelectWindow] = useState(false);

    const navigate = useNavigate();
    

    useEffect(() => {
      fetchEvents();    
    }, []);
    
      // useEffect(() => {
      //   setCurrentEvents([...userEvents,...selectedEvents])
      // }, [userEvents,selectedEvents]);

    useEffect(() => {
      if (firstLoaded.current) {
        firstLoaded.current = false;
        return;
      }
      setIsLoading(false);
    }, [currentEvents]);
    
      // useEffect(() => {
      //   if (focusDate && calendarRef.current) {
      //     const calendarApi = calendarRef.current.getApi();
      //     calendarApi.gotoDate(focusDate);
      //     setFocusDate(null); // Reset focus date after navigating
      //   }
      // }, [focusDate]);
      
    const fetchEvents = async () => {
        // const shopHours = await getShopAvailableHours(shopId)
        // const publicAppointments = await getAppointmentInfo(shopId)
        var events_list = await getUserAppointments()
        events_list.forEach((event) => {
          event.backgroundColor = null
        })
        await setCurrentEvents(events_list)
    }
      
    const handleEventClick = async (clickInfo: any) => {
      refClickInfo.current = clickInfo
      setEventSelectWindow(true);     
    }
    
    
    
    const renderEventContent = (eventInfo: any) => {
      const eventDate = new Date(eventInfo.event.end);
      const isOldEvent = eventDate < today;
      return (
        <div style={{ backgroundColor: isOldEvent ? 'lightgray' : 'transparent', }}>
          <i>{eventInfo.event.title}</i>
          <br />
          <i>{eventInfo.event.extendedProps.service_name}</i>
          <br />
          {eventInfo.event.extendedProps.client_requests}
        </div>
      )
    }

  const dayCellDidMount = (arg) => {
      const dateNumber = arg.el.querySelector('.fc-daygrid-day-number');
      if (dateNumber) {
        dateNumber.style.pointerEvents = 'none';
        dateNumber.style.cursor = 'default';
      }
      else {
        return
      }
      const { date, el } = arg;
      // const dayEvents = currentEvents.filter(event => 
      //   event.start.toDateString() === date.toDateString()
      // );
  
      if (currentEvents.length > 0) {
        const allAvailable = currentEvents.every(event => (event as any).title! == 'available');
        const someAvailable = currentEvents.some(event => (event as any).title! == 'available');
  
        if (allAvailable) {
          el.classList.add('fc-day-all-available');
        } else if (someAvailable) {
          el.classList.add('fc-day-some-available');
        } else {
            el.classList.add('fc-day-none-available');
        }
      }
    };

    const handleDateSelect = async (selectInfo: any) => {
      if (selectInfo.view.type === 'dayGridMonth') {
        let calendarApi = selectInfo.view.calendar
        calendarApi.changeView('timeGridDay', selectInfo.startStr);
        return
  
      }
  
      let calendarApi = selectInfo.view.calendar
  
      calendarApi.unselect() // clear date selection
      setConfirmWindowOpen(true)
  
      if (true) {
        return
      }
    }

    const handleDayCellContent = (arg) => {
      setTimeout(() => { // Delay the execution
        if (arg.el) { // Check if arg.el exists before adding listener
          arg.el.addEventListener('click', (e) => {
            e.preventDefault();
            const clickedDate = arg.date;
            console.log('Clicked date:', clickedDate);
            // ... your click handling logic ...
          });
        }
      }, 0); // A 0ms delay is often enough
    
      return arg.text;
    };
  
    const handleEventRemove = async () => {
        console.log(refClickInfo.current)
        const removeEvent = window.confirm(`Are you sure you want to delete the event ${refClickInfo.current.event.startStr.split('T')[1].split('+')[0].substr(0, 5)}-${refClickInfo.current.event.endStr.split('T')[1].split('+')[0].substr(0, 5)}?`)
        if (removeEvent) {
          await deleteAppointment(refClickInfo.current.event.id)
          await fetchEvents()
          focusDate.current = refClickInfo.current.event.start;
          setEventSelectWindow(false)
        }
      }
    if (isLoading) {
        return <div>Loading events...</div>;
    }
    else{
        return (
            <div>
                {/* Event select dialog */}
                <React.Fragment>
                <Dialog
                    open={eventSelectWindow}
                    TransitionComponent={Transition}
                    // keepMounted
                    onClose={() => { setEventSelectWindow(false) }}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{ 
                      '& .MuiDialog-paper': {
                        backgroundColor: "#fff8f5", // Change this to your desired background color
                      }
                    }}
                  >
                    <DialogContent>
                      <ShopCard shopInfo={{
                          header_image: refClickInfo.current ? refClickInfo.current.event.extendedProps.shop_image : null,
                          name: refClickInfo.current ? refClickInfo.current.event.title : null,
                          address: '',
                          email: '',
                          facebook: '',
                          geometry: { _lat: 0, _long: 0 },
                          instagram: '',
                          phone: '',
                          shop_services: null,
                          shopweeklyschedule: []
                      } as ShopInfo} 
                      mode="navigate"
                      onNavigate={() => navigate(`/shop/${refClickInfo.current?.event.extendedProps.shop_id}`)} />
                      <DialogContentText id="alert-dialog-slide-description" style={{ 
                          width: '100%', // Or any width you want the text to wrap within
                          wordWrap: 'break-word', // Ensure long words wrap to the next line
                          whiteSpace: 'pre-wrap', // Preserve spaces and wrap text
                          direction: 'rtl', // Align text from right to left
                          fontFamily: "'Roboto Slab', serif",
                        }}>
                          {refClickInfo.current !== null && refClickInfo.current.event.extendedProps.client_requests ? ( 
                          <>
                          <b>
                          בקשות הלקוח:
                          </b>
                          <br />
                          {refClickInfo.current.event.extendedProps.client_requests}
                          </>) : null}
                      </DialogContentText>
                        <Grid container flexDirection='row' justifyContent="center" sx={{background: "#fff8f5", marginTop: 2}}>
                            <Grid item sx={{ marginRight: 2 }}>
                                <ImageMenuButton  phoneNumber={refClickInfo.current ? refClickInfo.current.event.extendedProps.shop_phone: null}/>
                            </Grid>
                            <Grid item sx={{ marginLeft: 2 }}>
                              <Grid 
                              container 
                              flexDirection='column' 
                              alignItems="center" // Center items horizontally
                              justifyContent="center" // Center items vertically within the grid
                              sx={{
                                background: "#fff8f5",
                                // minHeight: '100vh', // Assuming you want it to take up the full viewport height
                                // or set a specific height if needed, e.g., height: '200px'
                              }}
                            >
                              <img src={deleteIcon} alt="Address logo" onClick={handleEventRemove} />
                              <>מחק</>
                            </Grid>
                          </Grid>
                      </Grid>
                      
                    </DialogContent>
                  </Dialog>
                </React.Fragment>
                <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin, momentPlugin]}
                locale={'en-gb'}
                titleFormat={'YYYY'}
                headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'today',
                }}
                initialView='listYear'
                // validRange={{
                //   start: thirtyDaysAgo,
                //   end: addDays(today, 365)
                // }}
                eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: true
                }}
                editable={true}
                selectable={false}
                // selectAllow={handleSelectAllow} // Allow selection based on custom logic
                selectMirror={true}
                // dayMaxEvents={true}
                events={currentEvents}
                slotDuration={{ minutes: slotGranularity }}
                // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
                // dateClick={handleDateSelect}
                select={handleDateSelect}

                eventContent={renderEventContent} // custom render function
                dayMaxEventRows={0} // This prevents the "+X more" link from appearing
                eventClick={handleEventClick}
                // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                slotMinTime="08:00:00" // Show only from 8:00 AM
                slotMaxTime="22:00:00" // Show only until 10:00 PM
                slotLabelInterval={{ minutes: slotGranularity }} // Show labels for every hour
                allDaySlot={false}
                navLinks={false} // Prevents clicking dates from navigating                selectLongPressDelay={0}
                height="auto"
                aspectRatio={1.35}
                dayCellDidMount={dayCellDidMount}
                dayCellContent={handleDayCellContent}
                // eventDidMount={handleEventDidMount}
                eventStartEditable={false}
                eventResizableFromStart={false}
                eventDurationEditable={false}
                
            />
            </div>
        );
    }
}

export default AccountCalendar;